import React, { useState, useLayoutEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from '../Firebase';

const UserList = props => {
  const [ state, setState ] = useState({
    loading: true,
    users: [],
    initalized: false,
  });
  useLayoutEffect(() => {
    const onUsersChange = () => {
      props.firebase.users().on('value', snapshot => {
        setState(state => ({ ...state, loading: true }))
        const usersObject = snapshot.val();
  
        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
  
        setState(state => ({
          ...state,
          users: usersList,
          loading: false,
        }));
      });
    }
    if (props.firebase && !state.firebaseStarted) {
      onUsersChange();
    }
  }, [props.firebase, state.firebaseStarted]);

  const { users, loading } = state;

  return (
    <div>
      <h2>Users</h2>
      {loading && <CircularProgress />}

      <ul>
        {users.map(user => (
          <li key={user.uid}>
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span>
              <strong>Username:</strong> {user.username}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withFirebase(UserList);
